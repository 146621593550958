<template>
	<div class="block_4">
		<div class="wrap">
			<pc></pc>
			<mobile></mobile>
		</div>
	</div>
</template>

<script>

import { useStore } from 'vuex'
import { computed } from 'vue'

import pc from './block4/pc.vue'
import mobile from './block4/mobile.vue'


export default {

	components: {
		'pc': pc,
		'mobile': mobile,
	},

	setup(){

		const store = useStore()
		const domain = computed(() => store.getters.domain)


		return { domain }
	},
}

</script>
