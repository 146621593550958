<template>
	<div class="block_5">
		<div class="wrap">

			<!--
				<div class="top">
					<h2 class="name">
						Формат проведения
					</h2>
					<div class="desc">
						деловых игр
					</div>
				</div>
			-->
			

			<div class="bottom">

				<div class="wrap">

					<h3 class="name">
						Онлайн
					</h3>

					<div class="desc">
						Мероприятие можно провести полностью удалённо: онлайн-игра проходит в общем зале со всеми участниками и в сессионных залах в небольших группах. Для коммуникации можно использовать Zoom, 
						Teams, чаты и другие подходящие инструменты. Команды сопровождают онлайн-кураторы — помощники ведущего и участников.
					</div>

					<div class="wrap">

						<svg width="20" height="4" viewBox="0 0 20 4" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M1.52 3.92C1.32 3.92 1.15 3.85 1.01 3.71C0.87 3.57 0.8 3.4 0.8 3.2V0.8C0.8 0.599999 0.87 0.429999 1.01 0.289999C1.15 0.149999 1.32 0.0799993 1.52 0.0799993H19.25C19.45 0.0799993 19.62 0.149999 19.76 0.289999C19.9 0.429999 19.97 0.599999 19.97 0.8V3.2C19.97 3.4 19.9 3.57 19.76 3.71C19.62 3.85 19.45 3.92 19.25 3.92H1.52Z" fill="#FFDD2D"/>
						</svg>

						<div class="name">
							Возможность объединить участников из разных городов
						</div>

					</div>

					<div class="wrap">

						<svg width="20" height="4" viewBox="0 0 20 4" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M1.52 3.92C1.32 3.92 1.15 3.85 1.01 3.71C0.87 3.57 0.8 3.4 0.8 3.2V0.8C0.8 0.599999 0.87 0.429999 1.01 0.289999C1.15 0.149999 1.32 0.0799993 1.52 0.0799993H19.25C19.45 0.0799993 19.62 0.149999 19.76 0.289999C19.9 0.429999 19.97 0.599999 19.97 0.8V3.2C19.97 3.4 19.9 3.57 19.76 3.71C19.62 3.85 19.45 3.92 19.25 3.92H1.52Z" fill="#FFDD2D"/>
						</svg>

						<div class="name">
							Высокий уровень вовлечения в командную работу
						</div>

					</div>

					<div class="wrap">

						<svg width="20" height="4" viewBox="0 0 20 4" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M1.52 3.92C1.32 3.92 1.15 3.85 1.01 3.71C0.87 3.57 0.8 3.4 0.8 3.2V0.8C0.8 0.599999 0.87 0.429999 1.01 0.289999C1.15 0.149999 1.32 0.0799993 1.52 0.0799993H19.25C19.45 0.0799993 19.62 0.149999 19.76 0.289999C19.9 0.429999 19.97 0.599999 19.97 0.8V3.2C19.97 3.4 19.9 3.57 19.76 3.71C19.62 3.85 19.45 3.92 19.25 3.92H1.52Z" fill="#FFDD2D"/>
						</svg>

						<div class="name">
							Удобная и непринуждённая атмосфера
						</div>

					</div>

					<slider :images="objects.one"></slider>

					<pc :images="objects.one"></pc>


				</div>

				<div class="wrap">

					<h3 class="name">
						Оффлайн
					</h3>

					<div class="desc">
						Игру можно провести и в традиционном очном формате. Каждая команда объединяется за своим столом, а ведущий транслирует игровую информацию как на общий экран, так и на устройства игроков. 
						Игра масштабируется на любое количество участников - как и в онлайне.
					</div>

					<div class="wrap">

						<svg width="20" height="4" viewBox="0 0 20 4" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M1.52 3.92C1.32 3.92 1.15 3.85 1.01 3.71C0.87 3.57 0.8 3.4 0.8 3.2V0.8C0.8 0.599999 0.87 0.429999 1.01 0.289999C1.15 0.149999 1.32 0.0799993 1.52 0.0799993H19.25C19.45 0.0799993 19.62 0.149999 19.76 0.289999C19.9 0.429999 19.97 0.599999 19.97 0.8V3.2C19.97 3.4 19.9 3.57 19.76 3.71C19.62 3.85 19.45 3.92 19.25 3.92H1.52Z" fill="#FFDD2D"/>
						</svg>

						<div class="name">
							Личное общение и персональный нетворкинг
						</div>

					</div>

					<div class="wrap">

						<svg width="20" height="4" viewBox="0 0 20 4" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M1.52 3.92C1.32 3.92 1.15 3.85 1.01 3.71C0.87 3.57 0.8 3.4 0.8 3.2V0.8C0.8 0.599999 0.87 0.429999 1.01 0.289999C1.15 0.149999 1.32 0.0799993 1.52 0.0799993H19.25C19.45 0.0799993 19.62 0.149999 19.76 0.289999C19.9 0.429999 19.97 0.599999 19.97 0.8V3.2C19.97 3.4 19.9 3.57 19.76 3.71C19.62 3.85 19.45 3.92 19.25 3.92H1.52Z" fill="#FFDD2D"/>
						</svg>

						<div class="name">
							Технологичность и гибкость игровых сценариев
						</div>

					</div>

					<div class="wrap">

						<svg width="20" height="4" viewBox="0 0 20 4" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M1.52 3.92C1.32 3.92 1.15 3.85 1.01 3.71C0.87 3.57 0.8 3.4 0.8 3.2V0.8C0.8 0.599999 0.87 0.429999 1.01 0.289999C1.15 0.149999 1.32 0.0799993 1.52 0.0799993H19.25C19.45 0.0799993 19.62 0.149999 19.76 0.289999C19.9 0.429999 19.97 0.599999 19.97 0.8V3.2C19.97 3.4 19.9 3.57 19.76 3.71C19.62 3.85 19.45 3.92 19.25 3.92H1.52Z" fill="#FFDD2D"/>
						</svg>

						<div class="name">
							Возможность выбрать наиболее подходящую площадку
						</div>

					</div>

					<slider :images="objects.tho"></slider>
					
					<pc :images="objects.tho"></pc>

				</div>


			</div>

		</div>
	</div>
</template>

<script>

import { useStore } from 'vuex'
import { computed, ref } from 'vue'

import slider from './block5/slider.vue'
import pc from './block5/pc.vue'


export default {

	components: {
		'pc': pc,
		'slider': slider,
	},

	setup(){

		const store = useStore()
		const domain = computed(() => store.getters.domain)


		const objects = ref({

			one: [
				{id: 1, name: '/static/main/img/block5/online/1.webp', active: true,},
				{id: 2, name: '/static/main/img/block5/online/2.webp', active: false,},
				{id: 3, name: '/static/main/img/block5/online/3.webp', active: false,},
				{id: 4, name: '/static/main/img/block5/online/4.webp', active: false,},
			],

			tho: [
				{id: 1, name: '/static/main/img/block5/offline/1.webp', active: true,},
				{id: 2, name: '/static/main/img/block5/offline/2.webp', active: false,},
				{id: 3, name: '/static/main/img/block5/offline/3.webp', active: false,},
				{id: 4, name: '/static/main/img/block5/offline/4.webp', active: false,},
			],

		})

		return { domain, objects, }
	},
}

</script>
