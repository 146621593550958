<template>
	<div id="services" class="block_7">
		<div class="wrap">

			<div class="top">
				<h2 class="left">
					<span>Проведём игру</span><br> или предоставим доступ к платформе
				</h2>
				<div class="right">

					<div @click="select(false)" :class="['tab', {active: !mode}, 'btn']">
						Заказчикам
					</div>

					<div @click="select(true)" :class="['tab', {active: mode}, 'btn']">
						Партнерам
					</div>

				</div>
			</div>

			<div class="center">

				<comp1 v-if="mode"></comp1>

				<comp2 v-if="!mode"></comp2>

			</div>

			<a href="#orders" v-smooth-scroll class="btn">
				Оставить заявку
			</a>

		</div>
	</div>
</template>

<script>

import { useStore } from 'vuex'
import { computed, ref } from 'vue'

import Comp1 from '@/components/block7/Comp1.vue'
import Comp2 from '@/components/block7/Comp2.vue'

export default {

	components: {
		'comp1':  Comp1,
		'comp2':  Comp2,
	},

	setup(){

		const store = useStore()
		const domain = computed(() => store.getters.domain)

		const mode = ref(false)

		function select(bool) {
			mode.value = bool
		}

		return { domain, mode, select, }
	},
}

</script>
