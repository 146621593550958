

export const update_menu = (state, bool) => {
    state.menu = bool
}


export const update_success = (state, bool) => {
    state.success = bool
}

export const update_pop_up = (state, bool) => {
    state.pop_up = bool
}