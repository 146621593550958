<template>

    <div class="mobile">

        <div @click="close_menu" class="close">
            <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M19.2422 1.75146L1.74219 19.2515" stroke="#FFDD2D" stroke-width="2.33333" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M1.74219 1.75146L19.2422 19.2515" stroke="#FFDD2D" stroke-width="2.33333" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        </div>

        <div class="wrap">

            <div class="links">

                <router-link :to="{ name: `products` }" @click="scroll('products')" class="link">
					Деловые игры
				</router-link>

				<router-link :to="{ name: `home` }" @click="scroll('services')" class="link">
					Услуги
				</router-link>

				<router-link :to="{ name: `home` }" @click="scroll('cases')" class="link">
					Кейсы
				</router-link>

                <router-link :to="{ name: `home` }" @click="scroll('the-best')" class="link">
					Отзывы
				</router-link>

            </div>

            <a href="mailto:info@bsgames.ru" class="email">
                info@bsgames.ru
            </a>

            <div class="icons">

                <a target="_blank" href="https://vk.com/public214650411" class="icon">
                    <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M22.8593 29.4157C14.6702 29.4157 9.99931 23.6903 9.80469 14.1631H13.9067C14.0415 21.1558 17.0656 24.1178 19.4609 24.7285V14.1631H23.3234V20.1939C25.6888 19.9344 28.174 17.1861 29.0124 14.1631H32.8749C32.5589 15.7309 31.9292 17.2154 31.025 18.5237C30.1208 19.832 28.9617 20.9359 27.6201 21.7665C29.1176 22.5254 30.4404 23.5996 31.501 24.9181C32.5616 26.2366 33.336 27.7696 33.7731 29.4157H29.5214C29.129 27.9859 28.3316 26.706 27.2291 25.7364C26.1265 24.7668 24.7679 24.1506 23.3234 23.9651V29.4157H22.8593Z" fill="#FFDD2D"/>
                    </svg>
                </a>

                <a target="_blank" href="https://t.me/bsg_helper_bot" class="icon">
                    <svg width="45" height="44" viewBox="0 0 45 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M31.0828 13.7303L27.8085 30.8242C27.8085 30.8242 27.3503 32.0094 26.0918 31.441L18.5371 25.4426L18.502 25.4249C19.5225 24.476 27.4357 17.1082 27.7815 16.7742C28.3169 16.257 27.9846 15.949 27.3629 16.3398L15.6742 24.0267L11.1647 22.4554C11.1647 22.4554 10.455 22.194 10.3867 21.6256C10.3176 21.0563 11.188 20.7484 11.188 20.7484L29.5719 13.2801C29.5719 13.2801 31.0828 12.5926 31.0828 13.7303Z" fill="#FFDD2D"/>
                    </svg>
                </a>

                <a target="_blank" href="https://wa.me/79084413577" class="icon">
                    <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M30.5816 13.1412C28.4292 10.9963 25.5594 9.80469 22.5301 9.80469C16.2324 9.80469 11.1305 14.8889 11.1305 21.1647C11.1305 23.1507 11.6885 25.1368 12.6451 26.805L11.0508 32.6836L17.1093 31.0948C18.7834 31.9687 20.6169 32.4453 22.5301 32.4453C28.8278 32.4453 33.9297 27.3611 33.9297 21.0853C33.85 18.146 32.734 15.2861 30.5816 13.1412ZM28.0306 25.2162C27.7915 25.8517 26.6754 26.4873 26.1174 26.5667C25.6391 26.6461 25.0014 26.6461 24.3636 26.4873C23.965 26.3284 23.407 26.1695 22.7693 25.8517C19.8994 24.6601 18.0659 21.8002 17.9065 21.5619C17.7471 21.403 16.7107 20.0525 16.7107 18.6226C16.7107 17.1927 17.4282 16.5572 17.6673 16.2394C17.9065 15.9216 18.2254 15.9216 18.4645 15.9216C18.624 15.9216 18.8631 15.9216 19.0225 15.9216C19.182 15.9216 19.4211 15.8422 19.6603 16.3983C19.8994 16.9544 20.4575 18.3843 20.5372 18.4637C20.6169 18.6226 20.6169 18.7815 20.5372 18.9404C20.4575 19.0993 20.3777 19.2581 20.2183 19.417C20.0589 19.5759 19.8994 19.8142 19.8197 19.8937C19.6603 20.0525 19.5008 20.2114 19.6603 20.4498C19.8197 20.7675 20.3777 21.6414 21.2546 22.4358C22.3707 23.3891 23.2476 23.7068 23.5664 23.8657C23.8853 24.0246 24.0447 23.9451 24.2042 23.7863C24.3636 23.6274 24.9216 22.9919 25.0811 22.6741C25.2405 22.3563 25.4797 22.4358 25.7188 22.5152C25.958 22.5947 27.3929 23.3096 27.632 23.4685C27.9509 23.6274 28.1103 23.7068 28.1901 23.7863C28.2698 24.0246 28.2698 24.5807 28.0306 25.2162Z" fill="#FFDD2D"/>
                    </svg>
                </a>

            </div>

            <div @click="open_pop_up" class="btn">
                Заказать звонок
            </div>

        </div>
    </div>

</template>

<script>

import { useStore } from 'vuex'

export default {
	setup(){

        const store = useStore()

		function open_pop_up(){
			store.dispatch('update_menu', false); store.dispatch('update_pop_up', true)
		}

        function close_menu(){
			store.dispatch('update_menu', false)
		}

        function smoothScrollTo(elementId, paddingTop = 0) {
			const element = document.getElementById(elementId);
			const menu = document.querySelector('.head');
			if (!element || !menu) return;

			const rect = element.getBoundingClientRect();
			const menuHeight = menu.getBoundingClientRect().height;
			const absoluteElementTop = window.scrollY + rect.top;
			const scrollToPosition = absoluteElementTop - paddingTop - menuHeight;

			window.scrollTo({
				top: scrollToPosition,
				behavior: 'smooth'
			});
		}

        function scroll(name){

			setTimeout(() => {
				smoothScrollTo(name)
			}, 500)


			store.dispatch('update_menu', false)
		}

		return { open_pop_up, close_menu, scroll, }
    
	},
}

</script>
