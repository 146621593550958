<template>
	<div class="head">

		<div class="wrap">

			<a itemprop="url" href="http://bsgames.ru" class="logo">
				BSGames
			</a>

			<div class="links hidden">

				<router-link :to="{ name: `products` }"  @click="scroll('products')" class="link">
					Деловые игры
				</router-link>

				<router-link :to="{ name: `home` }" @click="scroll('services')" class="link">
					Услуги
				</router-link>

				<router-link :to="{ name: `home` }" @click="scroll('cases')" class="link">
					Кейсы
				</router-link>

				<router-link :to="{ name: `home` }" @click="scroll('the-best')" class="link">
					Отзывы
				</router-link>

			</div>


			<div @click="open_pop_up" class="btn hidden">
				Связаться
			</div>


			<div class="contacts">
				<a href="tel:+79084413577" class="email hidden">
					+7 (908) 441-35-77
				</a>
				<a href="email:info@bsgames.ru" class="email hidden">
					info@bsgames.ru
				</a>
			</div>

			<div @click="open_menu" class="humburger">

				<svg width="30" height="20" viewBox="0 0 30 20" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M4 10H26.5" stroke="#FFDD2D" stroke-linecap="round" stroke-linejoin="round"/>
					<path d="M4 5H26.5" stroke="#FFDD2D" stroke-linecap="round" stroke-linejoin="round"/>
					<path d="M4 15H26.5" stroke="#FFDD2D" stroke-linecap="round" stroke-linejoin="round"/>
				</svg>

			</div>

			<mobile v-if="menu"></mobile>

		</div>

	</div>
</template>

<script>

import { computed } from 'vue'
import { useStore } from 'vuex'

import Mobile from '@/components/head/Mobile.vue'

export default {

	components: {
		'mobile': Mobile,
	},

	setup(){

		const store	= useStore()
		const menu	= computed(() => store.getters.menu)


		function open_pop_up(){
			store.dispatch('update_pop_up', true)
		}

		function open_menu(){
			store.dispatch('update_menu', true)
		}

		function smoothScrollTo(elementId, paddingTop = 0) {
			const element = document.getElementById(elementId);
			const menu = document.querySelector('.head');
			if (!element || !menu) return;

			const rect = element.getBoundingClientRect();
			const menuHeight = menu.getBoundingClientRect().height;
			const absoluteElementTop = window.scrollY + rect.top;
			const scrollToPosition = absoluteElementTop - paddingTop - menuHeight;

			window.scrollTo({
				top: scrollToPosition,
				behavior: 'smooth'
			});
		}


		function scroll(name){

			setTimeout(() => {
				smoothScrollTo(name)
			}, 500)


			store.dispatch('update_pop_up', false)
		}


		return { menu, open_pop_up, open_menu, scroll, }
    
	},
}

</script>
