<template>

  <div id="about" class="about">

    <div class="wrap">

      <router-link :to="{ name: `home` }" class="home">
        НА ГЛАВНУЮ
      </router-link>

      <div class="name">
        Политика <span>конфиденциальности</span>
      </div>

      <div class="wrap">

        <div class="name">
          <span>1</span> Общие положения
        </div>

        <p>
          1.1 Политика обработки персональных данных (далее – Политика) составлена в соответствии с требованиями Федерального закона от 27.07.2006 №152-ФЗ «О персональных данных».
        </p>

        <p>
          1.2 Политика определяет порядок обработки персональных данных и меры по обеспечению безопасности персональных данных, предпринимаемые BSGames (далее – Оператор) с целью защиты прав и свобод человека и гражданина при обработке его персональных данных, в том числе защиты прав на неприкосновенность частной жизни, личную и семейную тайну.
        </p>

        <p>
          1.3 Политика применяется ко всей информации, которую Оператор может получить о посетителях сайта https://bsgames.ru.
        </p>

      </div>

      <div class="wrap">

        <div class="name">
          <span>2</span> Основные понятия
        </div>

        <p>
          2.1 <span>Автоматизированная обработка персональных данных</span> – обработка персональных данных с помощью средств вычислительной техники.
        </p>

        <p>
          2.2 <span>Блокирование персональных данных</span> - временное прекращение обработки персональных данных (за исключением случаев, если обработка необходима для уточнения персональных данных).
        </p>

        <p>
          2.3 <span>Информационная система персональных данных</span> - совокупность содержащихся в базах данных персональных данных, и обеспечивающих их обработку информационных технологий и технических средств.
        </p>

        <p>
          2.4 <span>Обезличивание персональных данных</span> - действия, в результате которых невозможно определить без использования дополнительной информации принадлежность персональных данных конкретному субъекту персональных данных.
        </p>

        <p>
          2.5 <span>Обработка персональных данных</span> - любое действие (операция) или совокупность действий (операций), совершаемых с использованием средств автоматизации или без использования таких средств с персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных.
        </p>

        <p>
          2.5 <span>Обработка персональных данных</span> - любое действие (операция) или совокупность действий (операций), совершаемых с использованием средств автоматизации или без использования таких средств с персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных.
        </p>

        <p>
          2.6 <span>Оператор</span> - государственный орган, муниципальный орган, юридическое или физическое лицо, самостоятельно или совместно с другими лицами организующие и (или) осуществляющие обработку персональных данных, а также определяющие цели обработки персональных данных, состав персональных данных, подлежащих обработке, действия (операции), совершаемые с персональными данными.
        </p>

        <p>
          2.7 <span>Персональные данные</span> – любая информация, относящаяся к прямо или косвенно определенному или определяемому физическому лицу (субъекту персональных данных).
        </p>

        <p>
          2.8 <span>Предоставление персональных данных</span> – действия, направленные на раскрытие персональных данных определенному лицу или определенному кругу лиц
        </p>

        <p>
          2.9 <span>Распространение персональных данных</span> - действия, направленные на раскрытие персональных данных неопределенному кругу лиц (передача персональных данных) или на ознакомление с персональными данными неограниченного круга лиц, в том числе обнародование персональных данных в средствах массовой информации, размещение в информационно- телекоммуникационных сетях или предоставление доступа к персональным данным каким-либо иным способом
        </p>

        <p>
          2.10 <span>Трансграничная передача персональных данных</span> - передача персональных данных на территорию иностранного государства органу власти иностранного государства, иностранному физическому или иностранному юридическому лицу.
        </p>

        <p>
          2.11 <span>Уничтожение персональных данных</span> - действия, в результате которых невозможно восстановить содержание персональных данных в информационной системе персональных данных и (или) результате которых уничтожаются материальные носители персональных данных.
        </p>
      </div>

      <div class="wrap">

        <div class="name">
          <span>3</span> Принципы обработки персональных данных
        </div>

        <p>
          Обработка персональных данных у Оператора осуществляется на основе следующих принципов:
        </p>

        <ul>

          <li>
            законности и справедливой основы;
          </li>

          <li>
            ограничения обработки персональных данных достижением конкретных, заранее определенных и законных целей;
          </li>

          <li>
            недопущения обработки персональных данных, несовместимой с целями сбора персональных данных;
          </li>

          <li>
            недопущения объединения баз данных, содержащих персональные данные, обработка которых осуществляется в целях, несовместимых между собой;
          </li>

          <li>
            обработки только тех персональных данных, которые отвечают целям их обработки;
          </li>

          <li>
            соответствия содержания и объема обрабатываемых персональных данных заявленным целям обработки;
          </li>

          <li>
            недопущения обработки персональных данных, избыточных по отношению к заявленным целям их обработки;
          </li>

          <li>
            обеспечения точности, достаточности и актуальности персональных данных по отношению к целям обработки персональных данных;
          </li>

          <li>
            уничтожения либо обезличивания персональных данных по достижении целей их обработки или в случае утраты необходимости в достижении этих целей, при невозможности устранения Оператором допущенных нарушений персональных данных, если иное не предусмотрено федеральным законом.
          </li>

        </ul>

      </div>

      <div class="wrap">

        <div class="name">
          <span>4</span> Цели обработки персональных данных
        </div>

        <p>
          Целью обработки персональных данных является информирование пользователей посредством отправки электронных писем и SMS-уведомлений, предоставления доступа пользователей к информации, сервисам и материалам, содержащимся на веб-ресурсе https://bsgames.ru, а также совершенствования услуг, предоставляемых Оператором.
        </p>

      </div>

      <div class="wrap">

        <div class="name">
          <span>5</span> Перечень собираемых персональных данных
        </div>

        <p>
          5.1 Персональная данные, предоставленные при регистрации: имя, номер телефона, адрес, электронная почта, возраст. Оператор обрабатывает персональные данные пользователя только в случае их заполнения и/или отправки самостоятельно через формы, расположенные на сайте.
        </p>

        <p>
          5.2 Электронные данные, собранные с помощью сервиса Яндекс.Метрика: файлы cookie, частота посещения сайта пользователями, страницы и сайты, на которых были пользователи до перехода на данный сайт и др.
        </p>

      </div>

      <div class="wrap">

        <div class="name">
          <span>6</span> Конфиденциальность персональных данных
        </div>

        <p>
          Оператор и иные лица, получившие доступ к персональным данным, обязаны не раскрывать третьим лицам и не распространять персональные данные без согласия субъекта персональных данных, если иное не предусмотрено федеральным законом.
        </p>

      </div>

      <div class="wrap">

        <div class="name">
          <span>7</span> Порядок сбора, хранения, передачи и других видов обработки персональных данных
        </div>

        <p>
          7.1 Безопасность персональных данных, которые обрабатываются Оператором, обеспечивается путем реализации правовых, организационных и технических мер, необходимых для выполнения в полном объеме требований действующего законодательства в области защиты персональных данных.
        </p>

        <p>
          7.2 Оператор обеспечивает сохранность персональных данных и принимает все возможные меры, исключающие доступ к персональным данным неуполномоченных лиц.
        </p>

        <p>
          7.3 Персональные данные пользователя никогда ни при каких условиях не будут переданы третьим лицам, за исключением случаев, связанных с исполнением действующего законодательства.
        </p>

        <p>
          7.4 Срок обработки персональных данных является неограниченным. Пользователь может в любой момент отозвать свое согласие на обработку персональных данных, направив Оператору уведомление посредством электронной почты на электронный адрес Оператора.
        </p>

      </div>

      <div class="wrap">

        <div class="name">
          <span>8</span> Заключительные положения
        </div>

        <p>
          Иные права и обязанности Оператора определяются законодательством Российской Федерации в области персональных данных.
        </p>

        <p>
          Должностные лица Оператора, виновные в нарушении норм, регулирующих обработку и защиту персональных данных, несут материальную, дисциплинарную, административную, гражданско-правовую или уголовную ответственность в порядке, установленном федеральными законами.
        </p>

      </div>

      </div>

  </div>
</template>
