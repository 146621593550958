<template>
	<div class="block_9">

		<div class="wrap">
			<div class="top">

				<h2 class="name">
					<span>Адаптируем игру </span>под нишу Вашего бизнеса
				</h2>

				<h3 class="desc">
					Чтобы сотрудникам было проще воспринимать информацию и чувствовать себя комфортно, мы можем адаптировать бизнес-симуляцию с учётом сферы деятельности Вашей компании:
				</h3>

			</div>

			<div class="center">

				<div class="left">

					<div class="wrap">

						<div class="icon">
							—
						</div>

						<div class="name">
							переработаем внутренний дизайн игры в соответствии с корпоративной стилистикой
						</div>

					</div>

					<div class="wrap">

						<div class="icon">
							—
						</div>

						<div class="name">
							создадим кастомизированный рыночный сценарий, близкий Вашему бизнесу
						</div>

					</div>

					<div class="wrap">

						<div class="icon">
							—
						</div>

						<div class="name">
							подготовим отдельную брендированную страницу мероприятия с инструкцией для участников
						</div>

					</div>

					<div class="wrap">

						<div class="icon">
							—
						</div>

						<div class="name">
							по запросу адаптируем механику симуляции под Ваши потребности
						</div>

					</div>

				</div>

				<div class="right">

					<svg width="235" height="2" viewBox="0 0 235 2" fill="none" xmlns="http://www.w3.org/2000/svg">
						<rect width="235" height="2" transform="matrix(1 0 0 -1 0 2)" fill="#1A2030"/>
					</svg>

					<div class="name">
						Дополнительно мы можем:
					</div>

					<div class="wrap">

						<div class="name">
							— организовать обучающую сессию для участников
						</div>

						<div class="name">
							— организовать ассессмент-центр и сформировать рейтинг участников с подробной обратной связью
						</div>

						<div class="name">
							— организовать выступления команд с презентацией их стратегий
						</div>

						<div class="name">
							— подготовить индивидуальные сертификаты для участников
						</div>

						<div class="name">
							— провести мероприятие на английском языке и адаптировать игровую панель, личный кабинет и правила игры
						</div>

					</div>

				</div>

			</div>

			<div class="bottom">
				<a href="#orders" v-smooth-scroll class="btn">
					Обсудить возможности
				</a>
			</div>

		</div>

	</div>
</template>


<script>

import { useStore } from 'vuex'
import { computed } from 'vue'


export default {
    setup(){

		const store = useStore()
		const domain = computed(() => store.getters.domain)


        return {
			domain,
        }
    
    }

}
</script>