<template>
	<div class="block_18">
		<div class="wrap">

			<div class="top">
				<div class="line"></div>
				<div class="name">
					Ответы на вопросы
				</div>
			</div>

			<div class="center">

				<div v-for="tab in tabs" :key="tab.id" class="wrap" @click="tab.active = !tab.active">
					<div class="wrap">
						<div class="name">{{ tab.name }}</div>

						<svg :class="[{'open': tab.active}]" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M8 12L16 20L24 12" stroke="#1A2030" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
						</svg>
					</div>

					<div v-show="tab.active" class="desc">{{ tab.desc }}</div>
				</div>

			</div>

		</div>
	</div>
</template>


<script>

import { useStore } from 'vuex'
import { computed, ref } from 'vue'

export default {

    setup(){

		const store = useStore()
		const domain = computed(() => store.getters.domain)

		const tabs = ref([
			{
				id: 1,
				name: "Сколько человек может быть в одной команде/игре?",
				desc: "Всего в одной игре могут принимать участие до 120 человек, при этом каждая команда состоит из 3-6 человек. Можно организовать и больше участников - тогда параллельно будут идти несколько игровых потоков.",
				active: true,
			},
			{
				id: 2,
				name: "Можно ли провести игру дистанционно?",
				desc: "Мы проводим как очные, так и дистанционные мероприятия - наша бизнес-симуляция адаптируется к любым условиям. Каждый из форматов имеет свои достоинства - давайте обсудим!",
				active: false,
			},
			{
				id: 3,
				name: "Как можно заранее узнать, из чего состоит игровой процесс?",
				desc: "Мы всегда можем встретиться и провести для Вас демо: покажем игровые панели, предоставим доступ в личный кабинет и поможем представить себя на месте участника.",
				active: false,
			},
			{
				id: 4,
				name: "Можно ли оплатить сразу несколько игр?",
				desc: "Конечно, вы можете внести полную предоплату, это значительно упростит процесс оформления. Обычно мы выставляем счёт на предоплату в размере 30% от стоимости наших услуг.",
				active: false,
			},
		])


		return { domain, tabs }
    
    }

}
</script>

<style lang="sass" scoped>
@import '@/assets/sass/block18'
</style>

