<template>
	<div class="mobile">
		<img :src="domain + '/static/main/img/block4/1.svg'">
	</div>
</template>

<script>

import { useStore } from 'vuex'
import { computed } from 'vue'

export default {

	setup(){

		const store = useStore()
		const domain = computed(() => store.getters.domain)

		return { domain }
	},
}

</script>
