<template>
	<div class="success">

		<div @click="close" class="back"></div>

		<div class="wrap">

			<svg @click="close" width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M19.2422 1.75146L1.74219 19.2515" stroke="#FFDD2D" stroke-width="2.33333" stroke-linecap="round" stroke-linejoin="round"/>
				<path d="M1.74219 1.75146L19.2422 19.2515" stroke="#FFDD2D" stroke-width="2.33333" stroke-linecap="round" stroke-linejoin="round"/>
			</svg>

			<div class="name">
				Заявка отправлена
			</div>

			<div class="desc">
				Спасибо за обращение! Наши сотрудники свяжутся с Вами в ближайшее время и проконсультируют по всем вопросам.
			</div>

			<div @click="close" class="btn">
				Понял, жду!
			</div>

		</div>
	</div>
</template>

<script>

import { useStore } from 'vuex'

export default {

	setup(){

		const store = useStore()

		function close(){
			store.dispatch('update_success', false)
		}

		return { close }
	},
}

</script>
