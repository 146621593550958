<template>

	<div class="wrap">

		<div class="input">
			<input v-model="order.name" type="text" placeholder="Имя*" />
		</div>

		<div v-if="err.name" class="err">
			<span v-for="(name, i) in err.name" :key="i" class="err">
				{{ name }}
			</span>
		</div>

		<div class="input">
			<input v-model="order.email" type="text" placeholder="E-mail*" />
		</div>

		<div v-if="err.email" class="err">
			<span v-for="(name, i) in err.email" :key="i" class="err">
				{{ name }}
			</span>
		</div>

		<div class="input">
			<input v-model="order.phone" v-mask="['+7(###) ###-##-##',]" type="tel" placeholder="Номер телефона*" />
		</div>

		<div v-if="err.phone" class="err">
			<span v-for="(name, i) in err.phone" :key="i" class="err">
				{{ name }}
			</span>
		</div>

		<div class="input">
			<input v-model="order.info" type="text" placeholder="Компания или ссылка на сайт*" />
		</div>

		<div v-if="err.info" class="err">
			<span v-for="(name, i) in err.info" :key="i" class="err">
				{{ name }}
			</span>
		</div>

		<div class="input">
			<input v-model="order.comment" type="text" placeholder="Ваши пожелания" />
		</div>

		<div v-if="err.comment" class="err">
			<span v-for="(name, i) in err.comment" :key="i" class="err">
				{{ name }}
			</span>
		</div>

	</div>

	<button @click.prevent="send" type="submit" class="btn">Свяжитесь со мной, очень жду!</button>

	<div class="desc_2">

		Нажимая на кнопку, Вы даёте согласие на 

		<router-link @click="close" :to="{ name: `about` }">
			обработку персональных данных
		</router-link>
		
	</div>

</template>


<script>

import { useStore } from 'vuex'
import { computed, ref } from 'vue'

import { mask } from 'vue-the-mask'



export default {

	directives: {
		mask,
	},

    setup(){

		const store = useStore()
		const domain = computed(() => store.getters.domain)

		function close(){
			
			setTimeout(() => {
				document.getElementById("about").scrollIntoView({ 
					behavior: 'smooth' 
				})
			}, 500)


			store.dispatch('update_pop_up', false)
		}

		const err = ref({
			name: 		"",
			email: 		"",
			phone: 		"",
			info: 		"",
			comment: 	"",
		})

        const order = ref({
			name: 		"",
			email: 		"",
			phone: 		"",
			info: 		"",
			comment: 	"",
		})

		async function send(){

			let response = await fetch(`${domain.value}/api/v1/order/main/v2/`, {
				method: `POST`,
				headers: {
					'Content-Type':'application/json',
				},
				body: JSON.stringify(order.value)
			})


			if(response.ok){



				order.value = {
					name: 		"",
					email: 		"",
					phone: 		"",
					info: 		"",
					comment: 	"",
				}

				err.value = {
					name: 		"",
					email: 		"",
					phone: 		"",
					info: 		"",
					comment: 	"",
				}

				setTimeout(() => {
					close(); store.dispatch('update_success', true)
				}, 250)

			}else{

				const body = await response.json(); err.value = body

			}


		}

		return { domain, order, send, err, close }
    
    }

}
</script>