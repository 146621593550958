<template>
	<div class="wrap-hardware">

		<div class="hardware">

			<div class="wrap">

				<div @click="click_left" class="arrow-left">
					<svg width="35" height="70" viewBox="0 0 35 70" fill="none" xmlns="http://www.w3.org/2000/svg">
						<g style="mix-blend-mode:hard-light">
							<path d="M35 35C35 54.33 19.33 70 0 70V0C19.33 0 35 15.67 35 35Z" fill="#1A2030"/>
						</g>
						<path d="M17.7683 25.3073L8.34525 34.7304L17.7683 44.1535L15.8837 47.9227L2.69141 34.7304L15.8837 21.5381L17.7683 25.3073Z" fill="#FFDD2D"/>
					</svg>
				</div>
				
				<div class="wrap">
					<div class="sliders" :style="{'left': '-' + offset_left + 'px'}">
						<div v-for="slider in sliders" :key="slider.id" @click="open_box(slider)" v-touch:swipe.left="click_right" v-touch:swipe.right="click_left" :class="['slider', slider.class]">
							<img :src="domain + slider.img"/>
						</div>
					</div>
				</div>

				<div @click="click_right" class="arrow-right">
					<svg width="35" height="70" viewBox="0 0 35 70" fill="none" xmlns="http://www.w3.org/2000/svg">
						<g style="mix-blend-mode:hard-light">
							<path d="M0 35C0 54.33 15.67 70 35 70V0C15.67 0 0 15.67 0 35Z" fill="#1A2030"/>
						</g>
						<path d="M17.2317 25.3073L26.6547 34.7304L17.2317 44.1535L19.1163 47.9227L32.3086 34.7304L19.1163 21.5381L17.2317 25.3073Z" fill="#FFDD2D"/>
					</svg>
				</div>

			</div>

		</div>

		<box v-if="box.is" :close="box.close" :object="box.object" :sliders="sliders"></box>

	</div>
</template>


<script>

import { useStore } from 'vuex'
import { computed, ref, onMounted, onUnmounted } from 'vue'

import Box from '@/components/Box.vue'

export default {

	components: {
		'box': Box,
	},


    setup(){

		const store = useStore()
		const domain = computed(() => store.getters.domain)


		onMounted(() => window.addEventListener("resize", () => offset_left.value = 0))
		onUnmounted(() => window.removeEventListener("resize", () => offset_left.value = 0))


		const box = ref({is: false, object: null, close: null})
		function open_box(object) {

			box.value.is = true
			box.value.object = object
			box.value.close = () => box.value.is = false
		}


		const sliders = ref([

			{
				id: 1, 
				img: '/static/main/img/slider/1.webp',
				class: '',
			},

			{
				id: 2, 
				img: '/static/main/img/slider/2.webp',
				class: '',
			},

			{
				id: 3, 
				img: '/static/main/img/slider/3.webp',
				class: '',
			},

			{
				id: 4, 
				img: '/static/main/img/slider/4.webp',
				class: '',
			},

			{
				id: 5, 
				img: '/static/main/img/slider/5.webp',
				class: '',
			},

			{
				id: 6, 
				img: '/static/main/img/slider/6.webp',
				class: '',
			},

			{
				id: 7, 
				img: '/static/main/img/slider/7.webp',
				class: '',
			},

			{
				id: 8, 
				img: '/static/main/img/slider/8.webp',
				class: '',
			},

			{
				id: 9, 
				img: '/static/main/img/slider/9.webp',
				class: '',
			},

			{
				id: 10, 
				img: '/static/main/img/slider/10.webp',
				class: '',
			},

			{
				id: 11, 
				img: '/static/main/img/slider/11.webp',
				class: '',
			},

			{
				id: 12, 
				img: '/static/main/img/slider/12.webp',
				class: '',
			},

			{
				id: 13, 
				img: '/static/main/img/slider/13.webp',
				class: '',
			},

			{
				id: 14, 
				img: '/static/main/img/slider/14.webp',
				class: '',
			},

			{
				id: 15, 
				img: '/static/main/img/slider/15.webp',
				class: '',
			},

			{
				id: 16, 
				img: '/static/main/img/slider/16.webp',
				class: '',
			},

		])

		const pagination = ref({
            start: 0,
			stop: 4,
        })

		const offset_left = ref(0)


		function click_left() {
            if(pagination.value.start > 0){

                pagination.value.start -= 1
				pagination.value.stop -= 1

				if(document.documentElement.clientWidth > 1240){
					offset_left.value -= 315
				}else{
					offset_left.value -= 235
				}

            }
        }

        function click_right() {
            if(pagination.value.stop < sliders.value.length){
				
                pagination.value.start += 1
				pagination.value.stop += 1

				if(document.documentElement.clientWidth > 1240){
					offset_left.value += 315
				}else{
					offset_left.value += 235
				}

            }
        }


        return {
			domain,
			sliders,
            //sliders_filter,
			offset_left,
            click_right,
            click_left,
			open_box,
			box,
        }
    
    }

}
</script>