<template>

  <block-14></block-14>

  <block-15></block-15>

  <block-45></block-45>

  <block-6></block-6>

  <block-16></block-16>

  <block-115></block-115>

  <block-17></block-17>

  <block-9></block-9>

  <block-18></block-18>

  <block-13></block-13>

</template>

<script>
import Block18   from '../components/Block18.vue'
import Block17   from '../components/Block17.vue'
import Block9   from '../components/Block9.vue'
import Block11_5   from '../components/Block11_5.vue'
import Block16   from '../components/Block16.vue'
import Block13   from '../components/Block13.vue'
import Block14   from '../components/Block14.vue'
import Block15   from '../components/Block15.vue'
import Block4_5   from '../components/Block4_5.vue'
import Block6   from '../components/Block6.vue'


export default {

  components: {
    'block-18':  Block18,
    'block-17':  Block17,
    'block-9':  Block9,
    'block-115':  Block11_5,
    'block-16':  Block16,
    'block-6':  Block6,
    'block-45':  Block4_5,
    'block-13':  Block13,
    'block-14':  Block14,
    'block-15':  Block15,
  },

  setup(){

		return { }
	},
}
</script>