import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import AboutView from '../views/AboutView.vue'
import Products from '../views/Products.vue'
import Factory from '../views/Factory.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },

  {
    path: '/privacy',
    name: 'about',
    component: AboutView,
  },

  {
    path: '/products',
    name: 'products',
    component: Products,
  },

  {
    path: '/factory',
    name: 'factory',
    component: Factory,
  },

]

const router = createRouter({

  history: createWebHistory(),
  routes,
  // scrollBehavior (to, from, savedPosition) {
  //   if (savedPosition) {
  //     return savedPosition
  //   } else {
  //     return { top: 0 }
  //   }
  // }
})

export default router
