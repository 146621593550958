<template>

	<!-- блок на главной-->
	<div id="cases" class="block_10">

		<div class="wrap">

			<div class="top">

				<div class="left">

					<h2 class="name">
						Наши кейсы
					</h2>

					<div class="desc">
						{{ active.name }}
					</div>

				</div>

			</div>

			<div class="center">

				<div class="top">

					<div v-for="paragraph in active.paragraphs" :key="paragraph.id" class="wrap">

						<div class="name">
							{{ paragraph.name }}
						</div>

						<div class="desc">
							<div v-for="name in paragraph.names" :key="name.id" class="name" v-html="name.name"></div>
						</div>

					</div>

					<div class="bottom">

						<div class="wrap">

							<div class="name">
								{{ active.game.participants }}
							</div>

							<div class="desc">
								участников
							</div>

						</div>

						<div class="wrap">

							<div class="name">
								{{ active.game.count }}
							</div>

							<div class="desc">
								{{ get_name(active.game.count) }}
							</div>

						</div>

						<div class="wrap">

							<div class="name">
								{{ active.game.format }}
							</div>

							<div class="desc">
								формат
							</div>

						</div>

					</div>


				</div>

			</div>

			<div class="bottom">

				<div class="left">

					<img v-if="active.game.logo" :src="domain + active.game.logo" />

					<div class="name">
						{{ active.game.desc }}
					</div>

				</div>

				<div class="right">

					<div @click="prev" class="wrap left">

						<svg class="btn" width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M40 20.9912C40 17.0356 38.827 13.1688 36.6294 9.87981C34.4318 6.59083 31.3082 4.02738 27.6537 2.51363C23.9991 0.999877 19.9778 0.60381 16.0982 1.37551C12.2186 2.14722 8.65492 4.05203 5.85787 6.84908C3.06082 9.64613 1.15601 13.2098 0.3843 17.0894C-0.387405 20.969 0.00866318 24.9904 1.52242 28.6449C3.03617 32.2994 5.59962 35.423 8.8886 37.6206C12.1776 39.8182 16.0444 40.9912 20 40.9912C22.6264 40.9912 25.2272 40.4739 27.6537 39.4688C30.0802 38.4637 32.285 36.9905 34.1421 35.1333C35.9993 33.2762 37.4725 31.0714 38.4776 28.6449C39.4827 26.2184 40 23.6177 40 20.9912ZM16.28 28.3712L10.56 22.3712C10.4754 22.2844 10.4075 22.1827 10.36 22.0712C10.2751 21.9771 10.2074 21.8688 10.16 21.7512C10.0542 21.5118 9.99954 21.253 9.99954 20.9912C9.99954 20.7295 10.0542 20.4706 10.16 20.2312C10.2552 19.9857 10.3979 19.7614 10.58 19.5712L16.58 13.5712C16.9566 13.1946 17.4674 12.983 18 12.983C18.5326 12.983 19.0434 13.1946 19.42 13.5712C19.7966 13.9478 20.0082 14.4586 20.0082 14.9912C20.0082 15.5238 19.7966 16.0346 19.42 16.4112L16.82 18.9912H28C28.5304 18.9912 29.0391 19.2019 29.4142 19.577C29.7893 19.9521 30 20.4608 30 20.9912C30 21.5217 29.7893 22.0304 29.4142 22.4054C29.0391 22.7805 28.5304 22.9912 28 22.9912H16.68L19.18 25.6112C19.546 25.9958 19.7442 26.51 19.7311 27.0407C19.718 27.5714 19.4946 28.0752 19.11 28.4412C18.7254 28.8072 18.2112 29.0055 17.6805 28.9923C17.1498 28.9792 16.646 28.7558 16.28 28.3712Z" fill="#FFDD2D"/>
						</svg>

						<div class="name">
							Предыдущий кейс
						</div>

					</div>

					<div @click="next" class="wrap right">

						<div class="name">
							Следующий кейс
						</div>

						<svg class="btn" width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M0 20.9912C0 17.0356 1.17298 13.1688 3.37061 9.87981C5.56823 6.59083 8.69181 4.02738 12.3463 2.51363C16.0009 0.999877 20.0222 0.60381 23.9018 1.37551C27.7814 2.14722 31.3451 4.05203 34.1421 6.84908C36.9392 9.64613 38.844 13.2098 39.6157 17.0894C40.3874 20.969 39.9913 24.9904 38.4776 28.6449C36.9638 32.2994 34.4004 35.423 31.1114 37.6206C27.8224 39.8182 23.9556 40.9912 20 40.9912C17.3736 40.9912 14.7728 40.4739 12.3463 39.4688C9.91982 38.4637 7.71503 36.9905 5.85786 35.1333C4.00069 33.2762 2.5275 31.0714 1.52241 28.6449C0.517315 26.2184 0 23.6177 0 20.9912ZM23.72 28.3712L29.44 22.3712C29.5246 22.2844 29.5925 22.1827 29.64 22.0712C29.7249 21.9771 29.7926 21.8688 29.84 21.7512C29.9458 21.5118 30.0005 21.253 30.0005 20.9912C30.0005 20.7295 29.9458 20.4706 29.84 20.2312C29.7448 19.9857 29.6021 19.7614 29.42 19.5712L23.42 13.5712C23.0434 13.1946 22.5326 12.983 22 12.983C21.4674 12.983 20.9566 13.1946 20.58 13.5712C20.2034 13.9478 19.9918 14.4586 19.9918 14.9912C19.9918 15.5238 20.2034 16.0346 20.58 16.4112L23.18 18.9912H12C11.4696 18.9912 10.9609 19.2019 10.5858 19.577C10.2107 19.9521 10 20.4608 10 20.9912C10 21.5217 10.2107 22.0304 10.5858 22.4054C10.9609 22.7805 11.4696 22.9912 12 22.9912H23.32L20.82 25.6112C20.454 25.9958 20.2558 26.51 20.2689 27.0407C20.282 27.5714 20.5054 28.0752 20.89 28.4412C21.2746 28.8072 21.7888 29.0055 22.3195 28.9923C22.8502 28.9792 23.354 28.7558 23.72 28.3712Z" fill="#FFDD2D"/>
						</svg>

					</div>
				</div>

			</div>

		</div>
		
	</div>

</template>


<script>

import { useStore } from 'vuex'
import { computed, ref } from 'vue'


export default {


    setup(){

		const store = useStore()
		const domain = computed(() => store.getters.domain)

		function next() {
			// Получим индекс текущего активного объекта
			const currentIndex = objects.value.findIndex(object => object.active);

			// Если активный объект не найден, мы не можем продолжить
			if (currentIndex === -1) return;

			// Сделаем текущий активный объект неактивным
			objects.value[currentIndex].active = false; 

			// Вычислим индекс следующего объекта
			const nextIndex = (currentIndex + 1) < objects.value.length ? currentIndex + 1 : 0;

			// Сделаем следующий объект активным
			objects.value[nextIndex].active = true;
		}


		function prev() {
			// Получим индекс текущего активного объекта
			const currentIndex = objects.value.findIndex(object => object.active);
			
			// Если активный объект не найден, мы не можем продолжить
			if (currentIndex === -1) return;

			// Сделаем текущий активный объект неактивным
			objects.value[currentIndex].active = false; 

			// Вычислим индекс предыдущего объекта
			const prevIndex = currentIndex > 0 ? currentIndex - 1 : objects.value.length - 1;

			// Сделаем предыдущий объект активным
			objects.value[prevIndex].active = true;
		}

		const objects = ref([

			{
				id: 1,
				name: 'Стратегическая деловая игра ГК «Чайковский текстиль»',

				paragraphs: [
					{
						id: 1,
						name: 'Задача',
						names: [
							{
								id: 1,
								name: `Организовать стратегическую деловую игру, направленную на развитие навыков управления запасами, развития производства, построения коммерческой стратегии и инвестирования для топ-менеджмента компании.`,
							}
						],
					},

					{
						id: 2,
						name: 'Решение',
						names: [

							{
								id: 1,
								name: `<span>—</span> Провели комплексную бизнес-симуляцию, включающую в себя сессию командообразования, задания по планированию, а также управлению различными видами продукции.`,
							},
							{
								id: 2,
								name: `<span>—</span> В процессе мероприятия провели анализ показателей команд, подготовленный на основе принятых решений и полученных результатов.`,
							},
						],
					},

					{
						id: 3,
						name: 'Целевая аудитория',
						names: [
							{
								id: 1,
								name: `Руководители и ключевые сотрудники из всех подразделений компании`,
							}
						],
					},

				],

				game: {
					participants: '36',
					count: 1,
					format: 'очно',
					logo: '',
				},

				active: true,

			},

			{
				id: 2,
				name: 'Набор студентов на стажировку в Procter & Gamble',

				paragraphs: [
					{
						id: 1,
						name: 'Задача',
						names: [
							{
								id: 1,
								name: `Адаптировать бизнес-симуляцию «Фабрика»  под корпоративный стиль компании и провести деловую игру с целью осуществления набора студентов на стажировку на управленческие позиции.`,
							}
						],
					},

					{
						id: 2,
						name: 'Решение',
						names: [

							{
								id: 1,
								name: `<span>—</span> Сформировали программу, включающую в себя выступления представителей компании, бизнес-симуляцию с адаптированным под специфику FMCG рыночным сценарием, а также Q&A-сессию.`,
							},
							{
								id: 2,
								name: `<span>—</span> На базе игры провели оценку более 60 кандидатов, лучшие из которых получили приглашение на стажировку.`,
							},
						],
					},

					{
						id: 3,
						name: 'Целевая аудитория',
						names: [
							{
								id: 1,
								name: `Потенциальные кандидаты на трудоустройство — студенты старших курсов Тульского филиала Финансового университета при Правительстве РФ.`,
							}
						],
					},

				],

				game: {
					participants: '60+',
					count: 1,
					format: 'очно',
					logo: '/static/main/img/block11/1.png',
				},

				active: true,

			},

			{
				id: 3,

				name: 'Формирование кадрового резерва Siemens',

				paragraphs: [
					{
						id: 1,
						name: 'Задача',
						names: [
							{
								id: 1,
								name: `Создать систему оценки персонала для формирования кадрового резерва и познакомить друг с другом участников корпоративной программы развития - руководителей из различных регионов.`,
							}
						],
					},

					{
						id: 2,
						name: 'Решение',
						names: [

							{
								id: 1,
								name: `— Интегрировали бизнес-симуляции в полугодовую программу развития управленческих кадров Siemens Finance`,
							},
							{
								id: 2,
								name: `— Использовали результаты бизнес-симуляции для оценки сотрудников, разыграли поездку на корпоративное образовательное мероприятие.`,
							},
						],
					},

					{
						id: 3,
						name: 'Целевая аудитория',
						names: [
							{
								id: 1,
								name: `Руководители различных уровней, сотрудники компании из Санкт-Петербурга, Самары, Владивостока, Вологды и Калининграда`,
							}
						],
					},

				],

				game: {
					participants: '100+',
					count: 3,
					format: 'Онлайн',
					logo: '/static/main/img/block11/2.png',
				},

				active: false,
			},

			{
				id: 4,

				name: 'Программа кадрового резерва для Роснефти',

				paragraphs: [
					{
						id: 1,
						name: 'Задача',
						names: [
							{
								id: 1,
								name: `Провести серию тематических игр по нефтегазовой отрасли в рамках программы формирования кадрового резерва Роснефти «Школа-Вуз-Предприятие», адаптировав бизнес-симуляцию в соответствии с брендбуком компании.`,
							}
						],
					},

					{
						id: 2,
						name: 'Решение',
						names: [

							{
								id: 1,
								name: `<span>—</span> Провели серию деловых игр, позволивших компании продвинуть HR-бренд на рынке и привлечь потенциальных сотрудников.`,
							},
							{
								id: 2,
								name: `<span>—</span> Провели ассесмент, позволивший компании оценить кадровый резерв молодых специалистов.`,
							},

						],
					},

					{
						id: 3,
						name: 'Целевая аудитория',
						names: [
							{
								id: 1,
								name: `Молодые специалисты компании, студенты профильных направлений и учащиеся “Роснефть-классов”`,
							}
						],
					},

				],

				game: {
					participants: '300+',
					count: 8,
					format: 'Офлайн',
					logo: '/static/main/img/block11/3.png',
				},

				active: false,

			},


			{
				id: 5,

				name: 'Оценка управленческого персонала Первой Грузовой Компании',

				paragraphs: [
					{
						id: 1,
						name: 'Задача',
						names: [
							{
								id: 1,
								name: `Организовать онлайн ассесмент-центр, провести оценку управленческого состава компании (ТОП-1, ТОП-2) на основе утверждённой в компании модели компетенций и построить рейтинг участников для формирования кадрового резерва.`,
							}
						],
					},

					{
						id: 2,
						name: 'Решение',
						names: [

							{
								id: 1,
								name: `<span>—</span> Адаптировали бизнес-симуляцию под модель компетенций, принятую в компании.`,
							},
							{
								id: 2,
								name: `<span>—</span> На основе проведённых деловых игр сформировали итоговый отчёт, позволяющий принимать решения о дальнейшем развитии сотрудников.`,
							},
						],
					},

					{
						id: 3,
						name: 'Целевая аудитория',
						names: [
							{
								id: 1,
								name: `Руководители направлений, линейные менеджеры компании.`,
							}
						],
					},
				],

				game: {
					participants: '120',
					count: 5,
					format: 'Онлайн',
					logo: '/static/main/img/block11/4.png',
				},

				active: false,

			},

			{
				id: 6,

				name: 'Тимбилдинг-чемпионат для Газпром',

				paragraphs: [
					{
						id: 1,
						name: 'Задача',
						names: [
							{
								id: 1,
								name: `Провести деловую игру с целью командообразования и ознакомления участников трёхдневного кейс-чемпионата с принципами работы нефтегазовой отрасли.`,
							}
						],
					},

					{
						id: 2,
						name: 'Решение',
						names: [

							{
								id: 1,
								name: `<span>—</span> Провели для молодых специалистов бизнес-игру «ВИНК» - симулятор вертикально-интегрированной нефтяной компании.`,
							},
							{
								id: 2,
								name: `<span>—</span> Игра помогла участникам познакомиться, изучить принципы работы нефтегазовой компании и сформировать команды для прохождения кейс-чемпионата.`,
							},
						],
					},

					{
						id: 3,
						name: 'Целевая аудитория',
						names: [
							{
								id: 1,
								name: `Молодые специалисты из всех управляемых структурных подразделений и филиалов ПАО Газпром (Корпоративная лига Газпром).`,
							}
						],
					},

				],

				game: {
					participants: '70',
					count: 1,
					format: 'Онлайн',
					logo: '/static/main/img/block11/5.png',
				},

				active: false,

			},
		])

		const active = computed(() => objects.value.filter((o) => o.active)[0])

		function get_name(count){

			if(count < 2){
				return "игра"
			}

			if(count < 5){
				return "игры"
			}

			return "игр"
		}

        return {
			domain,
			active,
			next,
			prev,
			get_name,
        }
    
    }

}
</script>