<template>
	<div class="block_2">
		<div class="wrap">

			<div class="top">
				<img :src="`${domain}/static/main/img/block_2.png`"/>
			</div>

			<div class="bottom">

				<div itemprop="name" class="name">
					BSGames
				</div>

				<div class="desc">
					Мы предлагаем новый подход к обучению, оценке сотрудников и налаживанию работы в команде - в формате деловых игр.
					<br><br>
					Бизнес-симуляция позволяет пройти основные этапы организации и развития бизнеса, понять, как это работает на практике, оценить скиллы команды и найти точки роста каждого участника.
				</div>

			</div>

		</div>
	</div>
</template>

<script>

import { useStore } from 'vuex'
import { computed } from 'vue'

export default {

	setup(){

		const store = useStore()
		const domain = computed(() => store.getters.domain)

		return { domain }
	},
}

</script>
