<template>
	<div class="block_6">
		<div class="wrap">

			<div class="top">
				<div class="name">
					Проведём <span>демо продукта</span> 
				</div>
				<div class="desc">
					Обсудим запрос и предложим варианты адаптации игры под Ваши задачи
				</div>
			</div>

			<a href="#orders" v-smooth-scroll class="btn">
				Заказать демо
			</a>

		</div>
	</div>
</template>

<script>

import { useStore } from 'vuex'
import { computed } from 'vue'

export default {

	setup(){

		const store = useStore()
		const domain = computed(() => store.getters.domain)



		return { domain, }
	},
}

</script>
