<template>

  <my-head></my-head>

  <order v-if="pop_up"></order>

  <success v-if="success"></success>

  <router-view/>

  <my-footer></my-footer>

</template>

<script>

  import { useStore } from 'vuex'
  import { computed } from 'vue'
  import MyHead   from './components/MyHead.vue'
  import Order    from './components/Order.vue'
  import Success  from './components/Success.vue'

  import MyFooter   from './components/MyFooter.vue'

  export default {

    name: 'App',

    components: {
      'my-head':  MyHead,
      'order':    Order,
      'success':  Success,
      'my-footer':  MyFooter,
    },

    setup(){

      const store     = useStore()
      const pop_up    = computed(() => store.getters.pop_up)
      const success   = computed(() => store.getters.success)

      return { pop_up, success }
    },

  }
</script>