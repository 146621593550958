<template>
	<div class="pc">
		<img v-for="image in images" :key="image.id" :src="domain + image.name" />
	</div>
</template>

<script>

import { useStore } from 'vuex'
import { computed } from 'vue'

export default {

	props: {
		images: Array,
	},

	setup(){

		const store = useStore()
		const domain = computed(() => store.getters.domain)

		return { domain }
	},
}

</script>
