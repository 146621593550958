<template>
	<div class="block_17">
		<div class="wrap">
			<div class="name">
				<span>Фото-отчеты</span><br>
				проведенных мероприятий
			</div>
		</div>
	</div>
</template>


<script>

import { useStore } from 'vuex'
import { computed } from 'vue'

export default {

    setup(){

		const store = useStore()
		const domain = computed(() => store.getters.domain)


		return { domain }
    
    }

}
</script>

<style lang="sass" scoped>
@import '@/assets/sass/block17'
</style>

