<template>
	<div class="mobile">

		<svg width="33" height="397" viewBox="0 0 33 397" fill="none" xmlns="http://www.w3.org/2000/svg">
			<circle cx="15.5" cy="2.5" r="2.5" fill="white"/>
			<circle cx="15.5" cy="394.5" r="2.5" fill="white"/>
			<path fill-rule="evenodd" clip-rule="evenodd" d="M15 66C24.9696 65.8361 33.0001 57.84 33.0001 48C33.0001 38.16 24.9696 30.1639 15 30V31.478C24.1763 31.6415 31.5224 38.9991 31.5224 48C31.5224 57.0008 24.1763 64.3585 15 64.522V66Z" fill="white"/>
			<path fill-rule="evenodd" clip-rule="evenodd" d="M15 142C24.9696 141.836 33.0001 133.84 33.0001 124C33.0001 114.16 24.9696 106.164 15 106V107.478C24.1763 107.642 31.5224 114.999 31.5224 124C31.5224 133.001 24.1763 140.358 15 140.522V142Z" fill="white"/>
			<path fill-rule="evenodd" clip-rule="evenodd" d="M15 294C24.9696 293.836 33.0001 285.84 33.0001 276C33.0001 266.16 24.9696 258.164 15 258V259.478C24.1763 259.642 31.5224 266.999 31.5224 276C31.5224 285.001 24.1763 292.358 15 292.522V294Z" fill="white"/>
			<path fill-rule="evenodd" clip-rule="evenodd" d="M15 370C24.9696 369.836 33.0001 361.84 33.0001 352C33.0001 342.16 24.9696 334.164 15 334V335.478C24.1763 335.642 31.5224 342.999 31.5224 352C31.5224 361.001 24.1763 368.358 15 368.522V370Z" fill="white"/>
			<path fill-rule="evenodd" clip-rule="evenodd" d="M15 218C24.9696 217.836 33.0001 209.84 33.0001 200C33.0001 190.16 24.9696 182.164 15 182V183.478C24.1763 183.642 31.5224 190.999 31.5224 200C31.5224 209.001 24.1763 216.358 15 216.522V218Z" fill="white"/>
			<circle cx="15" cy="48" r="15" fill="#FFDD2D"/>
			<path d="M15.324 53C15.236 53 15.164 52.972 15.108 52.916C15.06 52.86 15.036 52.792 15.036 52.712V46.496L13.212 47.9C13.14 47.948 13.068 47.968 12.996 47.96C12.924 47.944 12.86 47.9 12.804 47.828L12.3 47.18C12.252 47.108 12.232 47.032 12.24 46.952C12.256 46.872 12.3 46.808 12.372 46.76L15.06 44.684C15.108 44.644 15.156 44.62 15.204 44.612C15.252 44.604 15.304 44.6 15.36 44.6H16.416C16.496 44.6 16.564 44.628 16.62 44.684C16.676 44.74 16.704 44.808 16.704 44.888V52.712C16.704 52.792 16.676 52.86 16.62 52.916C16.564 52.972 16.496 53 16.416 53H15.324Z" fill="#1A2030"/>
			<circle cx="15" cy="124" r="15" fill="#FFDD2D"/>
			<path d="M11.828 129C11.748 129 11.68 128.972 11.624 128.916C11.568 128.86 11.54 128.792 11.54 128.712V128.1C11.54 128.028 11.556 127.944 11.588 127.848C11.628 127.744 11.712 127.64 11.84 127.536L13.736 125.652C14.264 125.236 14.688 124.884 15.008 124.596C15.336 124.3 15.572 124.032 15.716 123.792C15.868 123.544 15.944 123.3 15.944 123.06C15.944 122.7 15.844 122.408 15.644 122.184C15.452 121.96 15.144 121.848 14.72 121.848C14.44 121.848 14.204 121.908 14.012 122.028C13.82 122.14 13.668 122.292 13.556 122.484C13.452 122.676 13.38 122.888 13.34 123.12C13.324 123.224 13.28 123.296 13.208 123.336C13.136 123.376 13.064 123.396 12.992 123.396H11.924C11.852 123.396 11.792 123.372 11.744 123.324C11.696 123.276 11.672 123.22 11.672 123.156C11.68 122.812 11.752 122.48 11.888 122.16C12.024 121.832 12.22 121.544 12.476 121.296C12.74 121.04 13.06 120.836 13.436 120.684C13.812 120.532 14.236 120.456 14.708 120.456C15.372 120.456 15.92 120.568 16.352 120.792C16.792 121.016 17.12 121.32 17.336 121.704C17.56 122.088 17.672 122.52 17.672 123C17.672 123.376 17.6 123.724 17.456 124.044C17.312 124.356 17.104 124.66 16.832 124.956C16.56 125.244 16.232 125.54 15.848 125.844L14.144 127.572H17.564C17.652 127.572 17.72 127.6 17.768 127.656C17.824 127.704 17.852 127.772 17.852 127.86V128.712C17.852 128.792 17.824 128.86 17.768 128.916C17.72 128.972 17.652 129 17.564 129H11.828Z" fill="#1A2030"/>
			<circle cx="15" cy="200" r="15" fill="#FFDD2D"/>
			<path d="M14.732 205.12C14.172 205.12 13.688 205.052 13.28 204.916C12.872 204.78 12.532 204.604 12.26 204.388C11.996 204.164 11.796 203.924 11.66 203.668C11.524 203.404 11.452 203.144 11.444 202.888C11.444 202.816 11.468 202.76 11.516 202.72C11.572 202.672 11.636 202.648 11.708 202.648H12.788C12.868 202.648 12.936 202.664 12.992 202.696C13.048 202.728 13.096 202.792 13.136 202.888C13.208 203.104 13.324 203.276 13.484 203.404C13.652 203.532 13.844 203.624 14.06 203.68C14.276 203.728 14.504 203.752 14.744 203.752C15.224 203.752 15.604 203.64 15.884 203.416C16.172 203.184 16.316 202.856 16.316 202.432C16.316 202.008 16.184 201.704 15.92 201.52C15.656 201.328 15.288 201.232 14.816 201.232H13.58C13.492 201.232 13.42 201.208 13.364 201.16C13.308 201.104 13.28 201.032 13.28 200.944V200.44C13.28 200.36 13.296 200.292 13.328 200.236C13.368 200.172 13.408 200.12 13.448 200.08L15.596 197.98H12.164C12.076 197.98 12.004 197.952 11.948 197.896C11.9 197.84 11.876 197.772 11.876 197.692V196.9C11.876 196.812 11.9 196.74 11.948 196.684C12.004 196.628 12.076 196.6 12.164 196.6H17.312C17.4 196.6 17.472 196.628 17.528 196.684C17.584 196.74 17.612 196.812 17.612 196.9V197.62C17.612 197.692 17.596 197.756 17.564 197.812C17.532 197.868 17.496 197.916 17.456 197.956L15.38 200.092L15.524 200.104C16.012 200.144 16.444 200.256 16.82 200.44C17.204 200.616 17.5 200.876 17.708 201.22C17.924 201.564 18.032 201.996 18.032 202.516C18.032 203.06 17.888 203.528 17.6 203.92C17.32 204.304 16.932 204.6 16.436 204.808C15.94 205.016 15.372 205.12 14.732 205.12Z" fill="#1A2030"/>
			<circle cx="15" cy="276" r="15" fill="#FFDD2D"/>
			<path d="M15.74 281C15.66 281 15.592 280.972 15.536 280.916C15.48 280.86 15.452 280.792 15.452 280.712V279.176H11.648C11.56 279.176 11.488 279.148 11.432 279.092C11.384 279.036 11.36 278.968 11.36 278.888V278.072C11.36 278.024 11.368 277.968 11.384 277.904C11.408 277.832 11.444 277.768 11.492 277.712L15.032 272.804C15.136 272.668 15.284 272.6 15.476 272.6H16.784C16.864 272.6 16.932 272.628 16.988 272.684C17.044 272.74 17.072 272.808 17.072 272.888V277.76H18.14C18.236 277.76 18.308 277.788 18.356 277.844C18.412 277.892 18.44 277.96 18.44 278.048V278.888C18.44 278.968 18.412 279.036 18.356 279.092C18.3 279.148 18.232 279.176 18.152 279.176H17.072V280.712C17.072 280.792 17.044 280.86 16.988 280.916C16.932 280.972 16.864 281 16.784 281H15.74ZM13.028 277.808H15.488V274.34L13.028 277.808Z" fill="#1A2030"/>
			<circle cx="15" cy="352" r="15" fill="#FFDD2D"/>
			<path d="M14.672 356.132C13.984 356.132 13.404 356.02 12.932 355.796C12.46 355.564 12.1 355.264 11.852 354.896C11.612 354.528 11.484 354.136 11.468 353.72C11.46 353.648 11.48 353.592 11.528 353.552C11.584 353.504 11.648 353.48 11.72 353.48H12.836C12.924 353.48 12.996 353.5 13.052 353.54C13.116 353.572 13.164 353.632 13.196 353.72C13.276 353.96 13.388 354.156 13.532 354.308C13.684 354.46 13.856 354.572 14.048 354.644C14.248 354.716 14.456 354.752 14.672 354.752C14.96 354.752 15.22 354.696 15.452 354.584C15.692 354.464 15.884 354.288 16.028 354.056C16.172 353.824 16.244 353.54 16.244 353.204C16.244 352.908 16.176 352.652 16.04 352.436C15.904 352.22 15.716 352.052 15.476 351.932C15.244 351.804 14.976 351.74 14.672 351.74C14.448 351.74 14.264 351.768 14.12 351.824C13.976 351.88 13.852 351.944 13.748 352.016C13.652 352.088 13.564 352.152 13.484 352.208C13.412 352.264 13.328 352.292 13.232 352.292H12.128C12.056 352.292 11.992 352.268 11.936 352.22C11.888 352.164 11.864 352.1 11.864 352.028L12.284 347.924C12.292 347.82 12.328 347.74 12.392 347.684C12.456 347.628 12.532 347.6 12.62 347.6H17.132C17.212 347.6 17.28 347.628 17.336 347.684C17.392 347.74 17.42 347.808 17.42 347.888V348.692C17.42 348.772 17.392 348.84 17.336 348.896C17.28 348.952 17.212 348.98 17.132 348.98H13.664L13.472 350.936C13.616 350.84 13.808 350.748 14.048 350.66C14.288 350.572 14.62 350.528 15.044 350.528C15.428 350.528 15.792 350.592 16.136 350.72C16.488 350.84 16.8 351.02 17.072 351.26C17.344 351.492 17.556 351.776 17.708 352.112C17.868 352.44 17.948 352.816 17.948 353.24C17.948 353.824 17.812 354.332 17.54 354.764C17.268 355.196 16.888 355.532 16.4 355.772C15.912 356.012 15.336 356.132 14.672 356.132Z" fill="#1A2030"/>
			<rect x="15" y="4" width="1.5" height="27" fill="white"/>
			<rect x="15" y="370" width="1.5" height="27" fill="white"/>
			<rect x="15" y="65" width="1" height="42" fill="white"/>
			<rect x="15" y="141" width="1.5" height="42" fill="white"/>
			<rect x="15" y="217" width="2" height="42" fill="white"/>
			<rect x="15" y="293" width="2" height="42" fill="white"/>
		</svg>


		<div class="wrap">

			<div class="name">
				Обсуждение
			</div>

			<div class="desc">
				Определим цели и проработаем концепцию Вашего мероприятия
			</div>

		</div>

		<div class="wrap">

			<div class="name">
				Подготовка программы
			</div>

			<div class="desc">
				Разработаем и предоставим подробную программу мероприятия
			</div>

		</div>

		<div class="wrap">

			<div class="name">
				Проведение бизнес-симуляции
			</div>

			<div class="desc">
				Организуем мероприятие в установленные сроки
			</div>

		</div>

		<div class="wrap">

			<div class="name">
				Рефлексия
			</div>

			<div class="desc">
				Проведем анализ полученного опыта и обсудим стратегии команд
			</div>

		</div>

		<div class="wrap">

			<div class="name">
				Оценка результатов
			</div>

			<div class="desc">
				Подведем итоги мероприятия и подготовим отчет
			</div>

		</div>

	</div>
</template>

<script>

import { useStore } from 'vuex'
import { computed } from 'vue'

export default {

	setup(){

		const store = useStore()
		const domain = computed(() => store.getters.domain)



		return { domain, }
	},
}

</script>
