<template>
	<div class="block_14" :style="[{background: `url(${domain}/static/main/img/block14/1.png) center center no-repeat`}]">
		<div class="wrap">
			<div class="top">
				ФАБРИКА <br><span>КОРПОРАТИВНАЯ ДЕЛОВАЯ ИГРА</span>
			</div>

		</div>
	</div>
</template>


<script>

import { useStore } from 'vuex'
import { computed } from 'vue'

export default {

    setup(){

		const store = useStore()
		const domain = computed(() => store.getters.domain)


		return { domain }
    
    }

}
</script>

<style lang="sass" scoped>
@import '@/assets/sass/block14'
</style>

