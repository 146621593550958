<template>
	<div id="the-best" class="block_11">
		<div class="wrap">

			<div class="top">

				<div class="line"></div>

				<div class="name">
					Работаем с лучшими
				</div>

			</div>

			<div  class="center">

				<div class="mobile">
					
					<div class="logo">
						<img :src="domain + active.logo">
					</div>
					
					<div class="wrap">

						<svg @click="prev" width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M30 15C30 12.0333 29.1203 9.13319 27.472 6.66645C25.8238 4.19972 23.4811 2.27713 20.7403 1.14181C17.9994 0.00649926 14.9834 -0.290551 12.0737 0.288228C9.16393 0.867006 6.49119 2.29562 4.3934 4.3934C2.29562 6.49119 0.867004 9.16394 0.288227 12.0737C-0.290552 14.9834 0.00649834 17.9994 1.14181 20.7403C2.27713 23.4811 4.19971 25.8238 6.66645 27.472C9.13319 29.1203 12.0333 30 15 30C16.9698 30 18.9204 29.612 20.7403 28.8582C22.5601 28.1044 24.2137 26.9995 25.6066 25.6066C26.9995 24.2137 28.1044 22.5601 28.8582 20.7403C29.612 18.9204 30 16.9698 30 15ZM12.21 20.535L7.92001 16.035C7.85652 15.9699 7.80566 15.8936 7.77001 15.81C7.70631 15.7394 7.65553 15.6582 7.62 15.57C7.54065 15.3905 7.49966 15.1963 7.49966 15C7.49966 14.8037 7.54065 14.6096 7.62 14.43C7.6914 14.2459 7.79845 14.0777 7.93501 13.935L12.435 9.43501C12.7175 9.15255 13.1006 8.99387 13.5 8.99387C13.8995 8.99387 14.2825 9.15255 14.565 9.43501C14.8475 9.71746 15.0061 10.1006 15.0061 10.5C15.0061 10.8995 14.8475 11.2825 14.565 11.565L12.615 13.5L21 13.5C21.3978 13.5 21.7794 13.658 22.0607 13.9393C22.342 14.2206 22.5 14.6022 22.5 15C22.5 15.3978 22.342 15.7794 22.0607 16.0607C21.7794 16.342 21.3978 16.5 21 16.5H12.51L14.385 18.465C14.6595 18.7534 14.8082 19.1391 14.7983 19.5371C14.7885 19.9352 14.6209 20.313 14.3325 20.5875C14.0441 20.862 13.6584 21.0107 13.2604 21.0008C12.8623 20.991 12.4845 20.8234 12.21 20.535Z" fill="#1A2030"/>
						</svg>

						<svg @click="next" width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M0 15C0 12.0333 0.879734 9.13319 2.52796 6.66645C4.17618 4.19972 6.51885 2.27713 9.25975 1.14181C12.0006 0.00649926 15.0166 -0.290551 17.9263 0.288228C20.8361 0.867006 23.5088 2.29562 25.6066 4.3934C27.7044 6.49119 29.133 9.16394 29.7118 12.0737C30.2906 14.9834 29.9935 17.9994 28.8582 20.7403C27.7229 23.4811 25.8003 25.8238 23.3335 27.472C20.8668 29.1203 17.9667 30 15 30C13.0302 30 11.0796 29.612 9.25975 28.8582C7.43986 28.1044 5.78628 26.9995 4.3934 25.6066C3.00052 24.2137 1.89563 22.5601 1.14181 20.7403C0.387986 18.9204 0 16.9698 0 15ZM17.79 20.535L22.08 16.035C22.1435 15.9699 22.1943 15.8936 22.23 15.81C22.2937 15.7394 22.3445 15.6582 22.38 15.57C22.4594 15.3905 22.5003 15.1963 22.5003 15C22.5003 14.8037 22.4594 14.6096 22.38 14.43C22.3086 14.2459 22.2016 14.0777 22.065 13.935L17.565 9.43501C17.2825 9.15255 16.8994 8.99387 16.5 8.99387C16.1005 8.99387 15.7175 9.15255 15.435 9.43501C15.1525 9.71746 14.9939 10.1006 14.9939 10.5C14.9939 10.8995 15.1525 11.2825 15.435 11.565L17.385 13.5L9 13.5C8.60217 13.5 8.22064 13.658 7.93934 13.9393C7.65803 14.2206 7.5 14.6022 7.5 15C7.5 15.3978 7.65803 15.7794 7.93934 16.0607C8.22064 16.342 8.60217 16.5 9 16.5H17.49L15.615 18.465C15.3405 18.7534 15.1918 19.1391 15.2017 19.5371C15.2115 19.9352 15.3791 20.313 15.6675 20.5875C15.9559 20.862 16.3416 21.0107 16.7396 21.0008C17.1377 20.991 17.5155 20.8234 17.79 20.535Z" fill="#1A2030"/>
						</svg>

					</div>

				</div>

				<div class="wrap">

					<div class="left">

						<img :src="domain + active.logo">

					</div>

					<div itemprop="review" itemscope itemtype="http://schema.org/Review"  class="center">

						<div class="wrap">

							<img :src="domain + active.user.avatar">

							<div class="wrap">
								<span itemprop="author" itemscope itemtype="http://schema.org/Person">
									<div itemprop="name" class="name">{{ active.user.name }}</div>
								</span>
								<div itemprop="reviewBody" class="desc">
									{{ active.user.desc }}
								</div>
							</div>
						
						</div>

						<div class="name">
							{{ active.name }}
						</div>
						<div class="desc">
							{{ active.desc }}
						</div>

					</div>

					<div class="right">

						<svg @click="prev" width="40" height="40" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M30 15C30 12.0333 29.1203 9.13319 27.472 6.66645C25.8238 4.19972 23.4811 2.27713 20.7403 1.14181C17.9994 0.00649926 14.9834 -0.290551 12.0737 0.288228C9.16393 0.867006 6.49119 2.29562 4.3934 4.3934C2.29562 6.49119 0.867004 9.16394 0.288227 12.0737C-0.290552 14.9834 0.00649834 17.9994 1.14181 20.7403C2.27713 23.4811 4.19971 25.8238 6.66645 27.472C9.13319 29.1203 12.0333 30 15 30C16.9698 30 18.9204 29.612 20.7403 28.8582C22.5601 28.1044 24.2137 26.9995 25.6066 25.6066C26.9995 24.2137 28.1044 22.5601 28.8582 20.7403C29.612 18.9204 30 16.9698 30 15ZM12.21 20.535L7.92001 16.035C7.85652 15.9699 7.80566 15.8936 7.77001 15.81C7.70631 15.7394 7.65553 15.6582 7.62 15.57C7.54065 15.3905 7.49966 15.1963 7.49966 15C7.49966 14.8037 7.54065 14.6096 7.62 14.43C7.6914 14.2459 7.79845 14.0777 7.93501 13.935L12.435 9.43501C12.7175 9.15255 13.1006 8.99387 13.5 8.99387C13.8995 8.99387 14.2825 9.15255 14.565 9.43501C14.8475 9.71746 15.0061 10.1006 15.0061 10.5C15.0061 10.8995 14.8475 11.2825 14.565 11.565L12.615 13.5L21 13.5C21.3978 13.5 21.7794 13.658 22.0607 13.9393C22.342 14.2206 22.5 14.6022 22.5 15C22.5 15.3978 22.342 15.7794 22.0607 16.0607C21.7794 16.342 21.3978 16.5 21 16.5H12.51L14.385 18.465C14.6595 18.7534 14.8082 19.1391 14.7983 19.5371C14.7885 19.9352 14.6209 20.313 14.3325 20.5875C14.0441 20.862 13.6584 21.0107 13.2604 21.0008C12.8623 20.991 12.4845 20.8234 12.21 20.535Z" fill="#1A2030"/>
						</svg>

						<svg @click="next" width="40" height="40" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M0 15C0 12.0333 0.879734 9.13319 2.52796 6.66645C4.17618 4.19972 6.51885 2.27713 9.25975 1.14181C12.0006 0.00649926 15.0166 -0.290551 17.9263 0.288228C20.8361 0.867006 23.5088 2.29562 25.6066 4.3934C27.7044 6.49119 29.133 9.16394 29.7118 12.0737C30.2906 14.9834 29.9935 17.9994 28.8582 20.7403C27.7229 23.4811 25.8003 25.8238 23.3335 27.472C20.8668 29.1203 17.9667 30 15 30C13.0302 30 11.0796 29.612 9.25975 28.8582C7.43986 28.1044 5.78628 26.9995 4.3934 25.6066C3.00052 24.2137 1.89563 22.5601 1.14181 20.7403C0.387986 18.9204 0 16.9698 0 15ZM17.79 20.535L22.08 16.035C22.1435 15.9699 22.1943 15.8936 22.23 15.81C22.2937 15.7394 22.3445 15.6582 22.38 15.57C22.4594 15.3905 22.5003 15.1963 22.5003 15C22.5003 14.8037 22.4594 14.6096 22.38 14.43C22.3086 14.2459 22.2016 14.0777 22.065 13.935L17.565 9.43501C17.2825 9.15255 16.8994 8.99387 16.5 8.99387C16.1005 8.99387 15.7175 9.15255 15.435 9.43501C15.1525 9.71746 14.9939 10.1006 14.9939 10.5C14.9939 10.8995 15.1525 11.2825 15.435 11.565L17.385 13.5L9 13.5C8.60217 13.5 8.22064 13.658 7.93934 13.9393C7.65803 14.2206 7.5 14.6022 7.5 15C7.5 15.3978 7.65803 15.7794 7.93934 16.0607C8.22064 16.342 8.60217 16.5 9 16.5H17.49L15.615 18.465C15.3405 18.7534 15.1918 19.1391 15.2017 19.5371C15.2115 19.9352 15.3791 20.313 15.6675 20.5875C15.9559 20.862 16.3416 21.0107 16.7396 21.0008C17.1377 20.991 17.5155 20.8234 17.79 20.535Z" fill="#1A2030"/>
						</svg>

					</div>
				</div>

			</div>

			<div class="bottom">

				<div class="wrap">
					<img :src="domain + `/static/main/img/block12/1.png`" />
				</div>

				<div class="wrap">
					<img :src="domain + `/static/main/img/block12/2.png`" />
				</div>

				<div class="wrap">
					<img :src="domain + `/static/main/img/block12/3.png`" />
				</div>

				<div class="wrap">
					<img :src="domain + `/static/main/img/block12/4.png`" />
				</div>

				<div class="wrap">
					<img :src="domain + `/static/main/img/block12/5.png`" />
				</div>

				<div class="wrap">
					<img :src="domain + `/static/main/img/block12/6.png`" />
				</div>

				<div class="wrap">
					<img :src="domain + `/static/main/img/block12/7.png`" />
				</div>

				<div class="wrap">
					<img :src="domain + `/static/main/img/block12/8.png`" />
				</div>

				<div class="wrap">
					<img :src="domain + `/static/main/img/block12/9.png`" />
				</div>

				<div class="wrap">
					<img :src="domain + `/static/main/img/block12/10.png`" />
				</div>

				<div class="wrap">
					<img :src="domain + `/static/main/img/block12/11.png`" />
				</div>

				<div class="wrap">
					<img :src="domain + `/static/main/img/block12/12.png`" />
				</div>

			</div>

		</div>
	</div>
</template>


<script>

import { useStore } from 'vuex'
import { computed, ref } from 'vue'


export default {
    setup(){

		const store = useStore()
		const domain = computed(() => store.getters.domain)


		function next() {
			// Получим индекс текущего активного объекта
			const currentIndex = objects.value.findIndex(object => object.active);

			// Если активный объект не найден, мы не можем продолжить
			if (currentIndex === -1) return;

			// Сделаем текущий активный объект неактивным
			objects.value[currentIndex].active = false; 

			// Вычислим индекс следующего объекта
			const nextIndex = (currentIndex + 1) < objects.value.length ? currentIndex + 1 : 0;

			// Сделаем следующий объект активным
			objects.value[nextIndex].active = true;
		}


		function prev() {
			// Получим индекс текущего активного объекта
			const currentIndex = objects.value.findIndex(object => object.active);
			
			// Если активный объект не найден, мы не можем продолжить
			if (currentIndex === -1) return;

			// Сделаем текущий активный объект неактивным
			objects.value[currentIndex].active = false; 

			// Вычислим индекс предыдущего объекта
			const prevIndex = currentIndex > 0 ? currentIndex - 1 : objects.value.length - 1;

			// Сделаем предыдущий объект активным
			objects.value[prevIndex].active = true;
		}


		const objects = ref([

			{
				id: 1,

				logo: '/static/main/img/block12/logo/9.png',

				user: {
					avatar: '/static/main/img/block12/avatars/9.png',
					name: 'Наталья Дьячкова',
					desc: 'Директор по персоналу Группы Компаний «Чайковский Текстиль»',
				},

				name: 'Деловая игра по стратегии управления компанией',
				desc: 'Мы выбрали данную игру по принципу комплексности подхода к управлению запасами, развития производства, построения коммерческой стратегии, инвестирования. В игре принимали участие руководители и ключевые сотрудники из всех подразделений компании. Тем самым производству удалось посмотреть на бизнес глазами коммерсантов, закупщикам - финансистов и так далее. Игра позволяет добиться развития навыков работы в команде, слышать друга друга и правильно распределять роли, ответственность. Мы получили более чем положительные отзывы наших коллег. Благодарим разработчиков и организаторов игры за внимание, гибкость, позитивный подход.',

				active: true,

			},


			{
				id: 2,

				logo: '/static/main/img/block12/logo/1.png',

				user: {
					avatar: '/static/main/img/block12/avatars/1.png',
					name: 'Надежда Галкина',
					desc: 'Менеджер по подбору и развитию персонала (HR Talent Practice)',
				},

				name: 'Проект по оценке кандидатов P&G Unsolvable Quest',
				desc: '«В конкурентной среде работодателей мы постоянно ищем интересные и современные способы привлечения внимания кандидатов. Одним из таких инструментов для нас стали бизнес-симуляции компании BSGames, которые не только знакомят аудиторию с компанией, но и позволяют на практике познакомить со спецификой нашей работы»',

				active: false,

			},
			
			{
				id: 3,

				logo: '/static/main/img/block12/logo/2.png',

				user: {
					avatar: '/static/main/img/block12/avatars/2.png',
					name: 'Дмитрий Ефремов',
					desc: 'Заместитель генерального директора Siemens Finance по Северо-Западному региону',
				},

				name: 'Программа развития управленческого персонала Siemens Finance',
				desc: '«В процессе игр участники научились самостоятельно распределять роли и разрабатывать стратегию для своих компаний. Участниками стали сотрудники компании из Санкт-Петербурга, Самары, Вологды, Владивостока и Калининграда»',

				active: false,

			},

			{
				id: 4,

				logo: '/static/main/img/block12/logo/3.png',

				user: {
					avatar: '/static/main/img/block12/avatars/3.png',
					name: 'Галина Токарева',
					desc: 'Советник директора по управлению и развитию персонала Фонда образовательных проектов «Надёжная смена»',
				},

				name: 'Кейс-чемпионат Корпоративной Лиги Газпром CASE-IN',
				desc: '«Симуляционная бизнес-игра была проведена для Корпоративной лиги ПАО «Газпром». Участникам удалось сформировать команды, наладить работу внутри них и получить совместный опыт управления компанией»',

				active: false,

			},

			{
				id: 5,

				logo: '/static/main/img/block12/logo/4.png',

				user: {
					avatar: '/static/main/img/block12/avatars/4.png',
					name: 'Марк Бащенко',
					desc: 'Менеджер образовательных проектов компании «Роснефть»',
				},

				name: 'Программа кадрового резерва Роснефти «Школа-вуз-предприятие»',
				desc: '«Нам необходимо было провести серию интересных игр по тематике работы Компании для молодых специалистов, а также для ребят, которых мы сможем в будущем привлечь для трудоустройства. Игра стала увлекательной возможностью проявить себя»',

				active: false,

			},

			{
				id: 6,

				logo: '/static/main/img/block12/logo/5.png',

				user: {
					avatar: '/static/main/img/block12/avatars/5.png',
					name: 'Мария Дрепа',
					desc: 'Директор компании «Business Life Stream», бизнес-тренер',
				},

				name: 'Программа формирования кадрового резерва ТОП-1 и ТОП-2',
				desc: '«На основе бизнес-симуляции была проведена оценка управленческого персонала центрального офиса. Мы оценивали лидерские качества, системное мышление, навыки управления коллективом и работы в команде. Предоставленные материалы  использованы для подготовки решений о дальнейшем развитии сотрудников»',

				active: false,

			},

			{
				id: 7,

				logo: '/static/main/img/block12/logo/6.png',

				user: {
					avatar: '/static/main/img/block12/avatars/6.png',
					name: 'Сергей Булатов',
					desc: 'Исполнительный директор ООО «Комитекс ГЕО»',
				},

				name: 'Стратегическая сессия топ-менеджмента компании',
				desc: '«Мы провели стратегическую сессию, в рамках которой знакомили и обучали управленческий состав компании, который затем оценивал результаты прошедшего года и строил стратегию развития бизнеса. Бизнес-симуляция помогла сблизить и настроить на совместную работу сотрудников из разных городов и отделов»',

				active: false,

			},

			{
				id: 8,

				logo: '/static/main/img/block12/logo/7.png',

				user: {
					avatar: '/static/main/img/block12/avatars/7.png',
					name: 'Николай Стецко',
					desc: 'Заместитель председателя Правительства Приморского края',
				},

				name: 'Обучение сотрудников Агентства проектного управления',
				desc: '«Игра была интересной и полезной, позволила смоделировать модель работы настоящей компании. Участники пробовали себя в разных ролях, за ограниченное время улучшили свои навыки в оценке рынка, конкурентов, стратегическом мышлении»',

				active: false,

			},

			{
				id: 9,

				logo: '/static/main/img/block12/logo/8.png',

				user: {
					avatar: '/static/main/img/block12/avatars/8.png',
					name: 'Елена Белова',
					desc: 'Руководитель отдела продаж компании Mango Games',
				},

				name: 'Образовательный интенсив для дивизиональных менеджеров',
				desc: '«Симуляция была использована для того, чтобы увидеть, как участники команд взаимодействуют друг с другом. Удалось провести параллель с работой: постоянным выпуском новых продуктов, их продвижением и выстраиванием продаж. После игры обсудили важность умения прийти к общему знаменателю, быстро подстраиваться под рынок, просчитывать свои действия и оценивать риски»',

				active: false,

			},

		])

		const active = computed(() => objects.value.filter((o) => o.active)[0])


        return {
			domain,
			active,
			next,
			prev,
        }
    
    }

}
</script>