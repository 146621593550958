<template>
	<div class="order">

		<div @click="close" class="back"></div>

		<div class="wrap">

			<svg @click="close" width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M19.2422 1.75146L1.74219 19.2515" stroke="#FFDD2D" stroke-width="2.33333" stroke-linecap="round" stroke-linejoin="round"/>
				<path d="M1.74219 1.75146L19.2422 19.2515" stroke="#FFDD2D" stroke-width="2.33333" stroke-linecap="round" stroke-linejoin="round"/>
			</svg>

			<div class="name">
				Оставьте заявку
			</div>

			<div class="desc">
				Обсудим задачи, проведем презентацию продукта и предложим варианты адаптации игры под нишу бизнеса
			</div>

			<my-form></my-form>

		</div>

	</div>
</template>

<script>

import { useStore } from 'vuex'
import { computed, onMounted, onUnmounted } from 'vue'

import MyForm from '../components/MyForm.vue'


export default {

	components: {
		'my-form': MyForm,
	},

	setup(){

		const store = useStore()
		const domain = computed(() => store.getters.domain)

		onUnmounted(() => {
			document.querySelector('body').classList.remove('scroll_none')
		})

		onMounted(() => {
			document.querySelector('body').classList.add('scroll_none')
		})

		function close(){
			store.dispatch('update_pop_up', false)
		}

		return { domain, close }
	},
}

</script>
