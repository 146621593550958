<template>
	<div class="block_1">
		<div class="wrap">

			<div class="top">

				<div class="left">

					<h1 class="name">
						Деловые игры для компаний в онлайн и оффлайн формате
					</h1>

					<div class="desc">
						с помощью бизнес-симуляторов
					</div>

					<a href="#cases" v-smooth-scroll class="btn">
						Хочу посмотреть кейсы
					</a>

				</div>

				<div class="right">

					<img itemprop="image" :src="`${domain}/static/main/img/mpro16.png`">

					<a href="#cases" v-smooth-scroll class="btn">
						Хочу посмотреть кейсы
					</a>

				</div>

			</div>

			<div itemprop="aggregateRating" itemscope itemtype="http://schema.org/AggregateRating" class="bottom">

				<div class="wrap">

					<div class="name">
						<span itemprop="ratingCount">4300</span>+ 
					</div>

					<div class="desc">
						человек уже приняли участие
					</div>

				</div>

				<div class="wrap">

					<div class="name">
						100+
					</div>

					<div class="desc">
						проведенных <br> игр
					</div>

				</div>

				<!-- <div class="wrap">

					<div itemprop="ratingValue" class="name">
						4,8
					</div>

					<div class="desc">
						средняя оценка пользователей
					</div>

				</div> -->

			</div>

		</div>
	</div>
</template>

<script>

import { useStore } from 'vuex'
import { computed } from 'vue'

export default {

	setup(){

		const store = useStore()
		const domain = computed(() => store.getters.domain)

		return { domain }
	},
}

</script>
