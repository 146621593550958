

export const update_menu = ({ commit }, bool) => {
    commit('update_menu', bool)
}

export const update_success = ({ commit }, bool) => {
    commit('update_success', bool)
}

export const update_pop_up = ({ commit }, bool) => {
    commit('update_pop_up', bool)
}