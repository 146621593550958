<template>
	<div class="comp_2">

		<mobile></mobile>

		<pc></pc>
		
	</div>
</template>

<script>

import { useStore } from 'vuex'
import { computed } from 'vue'

import mobile from '@/components/block7/comp2/mobile.vue'
import pc from '@/components/block7/comp2/pc.vue'

export default {

	components: {
		'mobile':  mobile,
		'pc': pc,
	},

	setup(){

		const store = useStore()
		const domain = computed(() => store.getters.domain)



		return { domain, }
	},
}

</script>
