<template>
	<div v-touch:swipe.left="click_right" v-touch:swipe.right="click_left" class="slider">

		<img :src="domain + active.name" />
		
		<div class="pagination">
			<div v-for="image in images" :key="image.id" @click="select(image)" :class="['name', {active: image.active}]"></div>
		</div>

	</div>
</template>

<script>

import { useStore } from 'vuex'
import { computed } from 'vue'

export default {

	props: {
		images: Array,
	},

	setup(props){

		const store 	= useStore()
		const domain 	= computed(() => store.getters.domain)
		const active 	= computed(() => props.images.filter((o) => o.active)[0])

		function click_left() {

			let temp = 0
            for(let object of props.images){
				if(object.active){

					if(object.id - 1 <= 0){
						return
					}

					temp = object.id - 1
				}

				object.active = false
			}

			for(let object of props.images){
				if(object.id == temp){
					object.active = true
				}
			}
			
        }

        function click_right() {

			let temp = 0
            for(let object of props.images){
				if(object.active){

					if(object.id + 1 > props.images.length){
						return
					}

					temp = object.id + 1
				}

				object.active = false
			}

			for(let object of props.images){
				if(object.id == temp){
					object.active = true
				}
			}

        }

		function select(object){

			for(let object of props.images){
				object.active = false
			}

			object.active = true
		}

		return { domain, active, select, click_left, click_right, }
	},
}

</script>
