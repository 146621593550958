<template>

  <block-1></block-1>

  <block-2></block-2>

  <block-3></block-3>

  <block-5></block-5>

  <block-6></block-6>

  <block-7></block-7>

  <block-8></block-8>

  <block-9></block-9>

  <block-10></block-10>

  <block-11></block-11>

  <block-12></block-12>

  <block-13></block-13>

</template>

<script>
import { useStore } from 'vuex'
import { computed } from 'vue'

import Block1   from '../components/Block1.vue'
import Block2   from '../components/Block2.vue'
import Block3   from '../components/Block3.vue'
import Block5   from '../components/Block5.vue'
import Block6   from '../components/Block6.vue'
import Block7   from '../components/Block7.vue'
import Block8   from '../components/Block8.vue'
import Block9   from '../components/Block9.vue'
import Block10   from '../components/Block10.vue'
import Block11   from '../components/Block11.vue'
import Block12   from '../components/Block12.vue'
import Block13   from '../components/Block13.vue'


export default {

  name: 'App',

  components: {

    'block-1':  Block1,
    'block-2':  Block2,
    'block-3':  Block3,
    'block-5':  Block5,
    'block-6':  Block6,
    'block-7':  Block7,
    'block-8':  Block8,
    'block-9':  Block9,
    'block-10':  Block10,
    'block-11':  Block11,
    'block-12':  Block12,
    'block-13':  Block13,

  },

  setup(){

    const store     = useStore()
		const pop_up    = computed(() => store.getters.pop_up)
    const success   = computed(() => store.getters.success)

		return { pop_up, success }
	},
}
</script>