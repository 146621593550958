<template>
	<div id="orders" class="block_13">
		<div class="wrap">

			<h2 class="left">
				Проведем игру или <span>поможем внедрить ее в Ваше мероприятие</span>
			</h2>


			<form action="/" method="get" onsubmit="ym(90238570,'reachGoal','send_contacts'); return true;" class="right">

				<div class="name">
					Оставьте заявку
				</div>

				<div class="desc">
					Обсудим задачи, проведем презентацию продукта и предложим варианты адаптации игры под нишу бизнеса
				</div>

				<my-form></my-form>

			</form>

		</div>

	</div>
</template>


<script>

import { useStore } from 'vuex'
import { computed } from 'vue'

import MyForm from '../components/MyForm.vue'

export default {

	components: {
		'my-form': MyForm,
	},

    setup(){

		const store = useStore()
		const domain = computed(() => store.getters.domain)


		return { domain }
    
    }

}
</script>